
window.onGMapsLoad = function() {
  const mapCenter = new google.maps.LatLng(50.979756, 11.032766);
  const logoPosition = new google.maps.LatLng(50.977226, 11.032509);

  const map = new google.maps.Map(document.getElementById("map-canvas"), {
    zoom: 14,
    backgroundColor: "#F4F4F4",
    center: mapCenter,
    maxZoom: 18,
    minZoom: 10,
    zoomControl: true,
    zoomControlOptions: {
      position: google.maps.ControlPosition.LEFT_CENTER,
    },
    mapTypeControl: false,
    panControl: false,
    rotateControl: false,
    streetViewControl: false,
    scrollwheel: false,
    mapTypeId: google.maps.MapTypeId.ROADMAP
  });

  const marker = new google.maps.Marker({
    position: logoPosition,
    icon: "../assets/icons/logo_EAT-ICON.png"
  });
  marker.setMap(map);

  window.onGMapsLoad = null;
}
